import Vue from 'vue';

import { httpClient } from '@api';

export default {
  async find(params) {
    return httpClient.post('/dispute/list', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
  async findOne(disputeId) {
    return httpClient.post(`/dispute/find-one/${disputeId}`, {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
    });
  },
  async update(payload) {
    return httpClient.post('/dispute/update', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...payload,
    });
  },
  async create(dispute) {
    return httpClient.post('/dispute/create', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...dispute,
    });
  },
  async getReasons() {
    return httpClient.post('/dispute/reasons', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
    });
  },
  async fetchDisputeChart() {
    return httpClient.post('/dispute/chart', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
    });
  },
  async generateDemo(params) {
    return httpClient.post('dispute/demo/generate', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
};
