import Vue from 'vue';

import { httpClient } from '@api';

import { Inbox } from './inbox.dto';
import { Email } from './email.dto';

export default {
  async list(params, tab) {
    const inboxData = await httpClient.post('/shared-inbox/get-many', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });

    const inbox = new Inbox({
      [tab]: inboxData.list,
      stats: inboxData.stats,
    });

    return new Inbox(inbox);
  },
  async findById(params) {
    const email = await httpClient.post('/shared-inbox/get-one', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });

    return new Email(email);
  },
  async updateById(params) {
    return httpClient.post('/shared-inbox/update-one', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
  async replyById(params) {
    return httpClient.post('/shared-inbox/reply', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
  async forwardById(params) {
    return httpClient.post('/shared-inbox/forward', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
  async markAsRead(params) {
    return httpClient.post('/shared-inbox/read-email', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
  async assignCustomer(params) {
    return httpClient.post('/shared-inbox/update-one', {
      companyId: Vue.prototype.$auth.user().selectedCompany.id,
      ...params,
    });
  },
};
