import {
  cloneDeep,
  isArray,
  isEmpty,
  isEqual,
  isNil,
  isString,
  toLower,
  head,
  split,
  get,
  startsWith,
} from 'lodash-es';
import { v4 as uuidv4 } from 'uuid';
import { format } from 'date-fns';
import Vue from 'vue';

import { ADAPTERS, NOTIFICATION, WORKFLOW } from '@enums';
import { backOfficeService } from '@services';
import { qbwcTemplate } from '@utils';
import { i18n } from '@plugins';

const {
  TYPES: { ALL_WORKFLOWS_RULES_INACTIVE },
} = NOTIFICATION;
const {
  TYPES: { PRINT, SMS },
} = WORKFLOW;
const {
  FILTER_ROW_RULES: {
    TYPES: { IS_IN, IS_NOT_IN, STARTS_WITH_ONE_FROM_LIST, IS_EMPTY, IS_NOT_EMPTY },
  },
  TYPES: { PLAID },
} = ADAPTERS;

const INBOX_DOMAINS = Object.freeze({
  US: 'us.inbox.gaviti.com',
  EU: 'eu.inbox.gaviti.com',
  STAGE: 'stage.inbox.gaviti.com',
  LOCALHOST: 'devgavitiemail.dev.gaviti.com',
});

const getInboxDomain = () => {
  const hostname = window.location.hostname;
  const subdomain = toLower(head(split(hostname, '.')));

  const DOMAIN_MAP = {
    us: INBOX_DOMAINS.US,
    stage: INBOX_DOMAINS.STAGE,
    localhost: INBOX_DOMAINS.LOCALHOST,
  };

  return get(DOMAIN_MAP, subdomain, startsWith(subdomain, 'dev') ? INBOX_DOMAINS.STAGE : INBOX_DOMAINS.EU);
};

const generateShortHash = (length = 16, lowerCase = false) => {
  const randomChars = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

  let result = '';

  for (let i = 0; i < length; i += 1) {
    result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
  }

  return lowerCase ? result.toLowerCase() : result;
};

export default {
  initStore: async ({ commit, dispatch }) => {
    try {
      commit('SET_IS_LOADING');

      const {
        selectedCompany: { id: companyId, type },
      } = Vue.auth.user();

      commit('SET_COMPANY_TYPE', type);

      await Promise.all([
        dispatch('fetchCompanySettingsData', companyId),
        dispatch('fetchCompanyAdaptersData', companyId),
        dispatch('fetchAdapterEmails', companyId),
        dispatch('settings/checkWorkflowConfigurations', null, { root: true }),
        dispatch('fetchBillingIds', companyId),
      ]);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_IS_LOADING');
    }
  },
  fetchCompanySettingsData: async ({ commit, dispatch }, companyId) => {
    try {
      const {
        notifyAboutRulesInactiveEnabled,
        workflowPrintEnabled,
        workflowSmsEnabled,
        uiShowPaymentForecasting,
        uiShowARTeam,
        customerContactLevels,
        userCustomerRelations,
        customFields,
        printNodePrinterId,
        printNodeRef,
        workflowSmsProvider,
        domain,
        isCreateMessageSent,
        goLiveTimestamp,
        invoiceMaxDueDateYears,
        businessType,
        billingId,
        sendApaOnBoardingEmail,
        registerGavitiCoreUsersWithHubspot,
        toggleGavitiEmailFooterLink,
        hideGavitiEmailFooter,
        emailTracking,
        customFieldsAsDueDate,
        deleted,
        isApaAccessToAuthorizationEnabled,
        isApaConnected,
        deactivatedAt,
        openaiTokens,
        companySettingsState,
        userCustomerRelationsLimit,
        customFieldsLimit,
        toggleCustomFields,
        toggleCreditManagement,
        toggleDisputeManagement,
        toggleBankReconciliation,
        toggleDisplayTransactionId,
        toggleInsights,
        toggleZapierIntegration,
        toggleDocuments,
        toggleOpenAiRephrase,
        toggleInvoicePreCollectionStatus,
        toggleWorkflowRuleLanguages,
        togglePhoneLink,
        exportToExternalStorage,
        toggleCreditReport,
        toggleCreditReportPDF,
        toggleCreditReportPaidPDF,
        creditReportLimit,
        creditFormLimit,
        sharedInbox,
      } = await backOfficeService.fetchSettings({ companyId });

      dispatch('setupCompanyWorkflowsConfig', {
        workflowSmsEnabled,
        workflowPrintEnabled,
        printNodePrinterId,
        printNodeRef,
        workflowSmsProvider,
      });

      commit('SET_COMPANY_NOTIFICATION_TYPES', {
        [ALL_WORKFLOWS_RULES_INACTIVE]: notifyAboutRulesInactiveEnabled,
      });
      commit('SET_COMPANY_WORKFLOW_TYPES', {
        [PRINT]: workflowPrintEnabled,
        [SMS]: workflowSmsEnabled,
      });
      commit('SET_COMPANY_PREMIUM_FEATURE_TYPES', {
        uiShowPaymentForecasting: isNil(uiShowPaymentForecasting) ? true : uiShowPaymentForecasting,
        isCreateMessageSent: isNil(isCreateMessageSent) ? false : isCreateMessageSent,
        sendApaOnBoardingEmail: isNil(sendApaOnBoardingEmail) ? false : sendApaOnBoardingEmail,
        registerGavitiCoreUsersWithHubspot: isNil(registerGavitiCoreUsersWithHubspot)
          ? false
          : registerGavitiCoreUsersWithHubspot,
        toggleGavitiEmailFooterLink: isNil(toggleGavitiEmailFooterLink) ? false : toggleGavitiEmailFooterLink,
        hideGavitiEmailFooter: isNil(hideGavitiEmailFooter) ? false : hideGavitiEmailFooter,
        emailTracking: isNil(emailTracking) ? true : emailTracking,
        sharedInbox: isNil(sharedInbox) ? { active: false, email: null } : sharedInbox,
        customFieldsAsDueDate: isNil(customFieldsAsDueDate) ? false : customFieldsAsDueDate,
        uiShowARTeam: isNil(uiShowARTeam) ? true : uiShowARTeam,
        customerContactLevels: isNil(customerContactLevels) ? 3 : customerContactLevels,
        domain: !isNil(domain) && isString(domain) ? [domain] : domain,
        invoiceMaxDueDateYears: isNil(invoiceMaxDueDateYears) ? 5 : invoiceMaxDueDateYears,
        billingId: isNil(billingId) ? '' : billingId,
        openaiTokens: isNil(openaiTokens) ? {} : openaiTokens,
        userCustomerRelationsLimit: isNil(userCustomerRelationsLimit) ? 3 : userCustomerRelationsLimit,
        toggleCustomFields: isNil(toggleCustomFields) ? true : toggleCustomFields,
        customFieldsLimit: isNil(customFieldsLimit) ? 3 : customFieldsLimit,
        toggleCreditManagement: isNil(toggleCreditManagement) ? false : toggleCreditManagement,
        toggleDisputeManagement: isNil(toggleDisputeManagement) ? false : toggleDisputeManagement,
        toggleCreditReport: isNil(toggleCreditReport) ? false : toggleCreditReport,
        toggleCreditReportPDF: isNil(toggleCreditReportPDF) ? false : toggleCreditReportPDF,
        toggleCreditReportPaidPDF: isNil(toggleCreditReportPaidPDF) ? false : toggleCreditReportPaidPDF,
        creditFormLimit: isNil(creditFormLimit) ? 0 : creditFormLimit,
        creditReportLimit: isNil(creditReportLimit) ? 0 : creditReportLimit,
        toggleBankReconciliation: isNil(toggleBankReconciliation) ? false : toggleBankReconciliation,
        toggleDisplayTransactionId: isNil(toggleDisplayTransactionId) ? false : toggleDisplayTransactionId,
        toggleInsights: isNil(toggleInsights) ? true : toggleInsights,
        toggleZapierIntegration: isNil(toggleZapierIntegration) ? false : toggleZapierIntegration,
        toggleDocuments: isNil(toggleDocuments) ? true : toggleDocuments,
        toggleOpenAiRephrase: isNil(toggleOpenAiRephrase) ? false : toggleOpenAiRephrase,
        toggleInvoicePreCollectionStatus: isNil(toggleInvoicePreCollectionStatus)
          ? false
          : toggleInvoicePreCollectionStatus,
        toggleWorkflowRuleLanguages: isNil(toggleWorkflowRuleLanguages) ? true : toggleWorkflowRuleLanguages,
        togglePhoneLink: isNil(togglePhoneLink) ? true : togglePhoneLink,
        exportToExternalStorage: isNil(exportToExternalStorage)
          ? {
              active: true,
              type: 's3',
              execution: {
                type: 'daily',
                timezone: '',
                time: null,
              },
              export: {
                customers: false,
                invoices: false,
                activities: false,
              },
              connection: {
                region: '',
                accessKeyId: '',
                secretAccessKey: '',
                bucket: '',
              },
            }
          : exportToExternalStorage,
      });
      commit('SET_COMPANY_APA_SETTINGS', {
        isApaAccessToAuthorizationEnabled,
        isApaConnected,
      });

      if (!isNil(userCustomerRelations) && !isEmpty(userCustomerRelations)) {
        commit('SET_COMPANY_UCR_TYPES', userCustomerRelations);
      }

      if (!isNil(customFields) && !isEmpty(customFields)) {
        commit('SET_COMPANY_CUSTOM_FIELD_TYPES', { ...customFields });
      } else {
        commit('SET_COMPANY_CUSTOM_FIELD_TYPES', {
          customer: {},
          invoice: {},
        });
      }

      if (!isNil(goLiveTimestamp)) {
        commit('SET_GO_LIVE_TIMESTAMP', format(new Date(goLiveTimestamp), 'yyyy-MM-dd'));
      }

      commit('SET_BUSINESS_TYPE', businessType || 'b2b');
      commit('SET_COMPANY_DELETED', deleted);
      commit('SET_COMPANY_DEACTIVATED', Boolean(deactivatedAt));
      commit('SET_COMPANY_SETTINGS_STATE', companySettingsState);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchCompanyAdaptersData: async ({ commit }, companyId) => {
    try {
      const adapters = await backOfficeService.fetchAdaptersByCompanyId({ companyId });

      if (!isNil(adapters)) {
        commit('SET_COMPANY_ADAPTERS', adapters);
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  verifyDomainList: async ({ commit }, { domain }) => {
    try {
      const companyId = Vue.auth.user()?.selectedCompany?.id;

      return await backOfficeService.verifyDomainList({ companyId, domain });
    } catch ({ message }) {
      console.error(message);
    }
  },
  setupCompanyWorkflowsConfig: (
    { commit },
    { workflowSmsEnabled, workflowPrintEnabled, printNodePrinterId, printNodeRef, workflowSmsProvider },
  ) => {
    commit('SET_COMPANY_WORKFLOW_TYPES', {
      [PRINT]: workflowPrintEnabled,
      [SMS]: workflowSmsEnabled,
    });

    commit('SET_COMPANY_WORKFLOW_TYPES_CONFIG', {
      name: PRINT,
      value: {
        printNodePrinterId: isNil(printNodePrinterId) ? '' : printNodePrinterId,
        printNodeRef: isNil(printNodeRef) ? '' : printNodeRef,
      },
    });

    commit('SET_COMPANY_WORKFLOW_TYPES_CONFIG', {
      name: SMS,
      value: isNil(workflowSmsProvider)
        ? {
            providerType: '',
            accountSid: '',
            authToken: '',
            from: '',
          }
        : workflowSmsProvider,
    });
  },
  presentModal: ({ commit }, payload) => {
    commit('SET_MODAL', {
      ...payload,
      open: true,
    });
  },
  dismissModal: ({ commit }) => {
    commit('SET_MODAL', {
      open: false,
      title: undefined,
      contentType: undefined,
    });
  },
  toggleReloadPage: ({ commit }, payload) => {
    commit('SET_RELOAD_PAGE', payload);

    location.reload();
  },
  updateCompanyType: async ({ commit }, value) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      commit('UPDATE_COMPANY_TYPE', value);

      await backOfficeService.updateSettings({
        companyId,
        name: 'type',
        value,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  toggleCompanyWorkflowType: async ({ commit }, { name, value }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      commit('TOGGLE_COMPANY_WORKFLOW_TYPE', { name, value });

      await backOfficeService.updateSettings({
        companyId,
        name: isEqual(name, PRINT) ? 'workflowPrintEnabled' : 'workflowSmsEnabled',
        value,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  updateCompanyWorkflowTypeConfig: async ({ commit, getters }, { name, value }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();
      const { companyWorkflowTypesConfig } = getters;

      Object.entries(value).map(([key, { value }]) => {
        commit('UPDATE_COMPANY_WORKFLOW_TYPE_CONFIG', {
          name,
          value: { [key]: value },
        });
      });

      if (isEqual(name, PRINT)) {
        Object.entries(companyWorkflowTypesConfig[name]).map(async ([name, value]) => {
          await backOfficeService.updateSettings({
            companyId,
            name,
            value,
          });
        });
      } else if (isEqual(name, SMS)) {
        await backOfficeService.updateSettings({
          companyId,
          name: 'workflowSmsProvider',
          value: companyWorkflowTypesConfig[name],
        });
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  toggleCompanyNotificationRulesInactive: async ({ commit }, { name, value }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      commit('TOGGLE_COMPANY_NOTIFICATION_TYPE', { name, value });

      await backOfficeService.updateSettings({
        companyId,
        name,
        value,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  toggleCompanyPremiumFeatureType: async ({ commit, getters, dispatch }, { name, value }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      commit('UPDATE_COMPANY_PREMIUM_FEATURE_TYPE', { name, value });

      if (isEqual(name, 'sharedInbox')) {
        const inboxDomain = getInboxDomain();

        const email = `${companyId}@${inboxDomain}`;

        value.email = email;
      }

      await backOfficeService.updateSettings({
        companyId,
        name,
        value,
      });

      if (isEqual(name, 'toggleBankReconciliation') && !value) {
        const { companyAdapters } = getters;

        const updatePromises = companyAdapters
          .filter(({ code }) => isEqual(code, PLAID.value))
          .map(async (adapter) => {
            const adapterConfig = cloneDeep(adapter);

            delete adapterConfig._id;

            adapterConfig.active = false;
            adapterConfig.activeChangeAt = Date.now();

            return backOfficeService.updateAdapters({
              companyId,
              name: adapterConfig.name,
              fields: adapterConfig,
            });
          });

        await Promise.all([...updatePromises, dispatch('fetchCompanyAdaptersData', companyId)]);
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  toggleCompanyAdapterType: async ({ commit, getters, dispatch }, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      commit('TOGGLE_COMPANY_ADAPTER_TYPE', payload);

      const { companyAdapters } = getters;

      const [adapter] = companyAdapters.filter((adapter) => {
        if (isEqual(adapter.name, payload.name)) {
          delete adapter._id;

          return adapter;
        }
      });

      const adapterConfig = cloneDeep(adapter);

      adapterConfig.activeChangeAt = Date.now();

      await backOfficeService.updateAdapters({
        companyId,
        name: adapterConfig.name,
        fields: adapterConfig,
      });

      await dispatch('fetchCompanyAdaptersData', companyId);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  updateCompanyAdapterTypeConfig: async ({ commit, dispatch }, { adapterConfig, editMode }) => {
    const {
      selectedCompany: { id: companyId },
    } = Vue.auth.user();

    try {
      const adapter = await dispatch('createAdapterInstance', {
        adapterConfig,
        editMode,
      });

      commit('UPDATE_COMPANY_ADAPTER_TYPE_CONFIG', adapter);

      if (adapter._id) {
        delete adapter._id;
      }

      await backOfficeService.updateAdapters({
        companyId,
        name: adapter.name,
        fields: adapter,
      });

      await dispatch('fetchCompanyAdaptersData', companyId);

      if (!editMode) {
        if (['bluesnap', 'stripe'].includes(adapter.code) && ['ACH', 'LBT', 'EFT'].includes(adapter.paymentType)) {
          dispatch(
            'settings/runAdapterMethod',
            {
              name: adapter.name,
              method: 'configureIPN',
            },
            { root: true },
          );
        }
      }
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  fetchRenewalsReport: async ({}, payload) => {
    try {
      return await backOfficeService.fetchRenewalsReport(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  clearCompany: async ({}, payload) => {
    try {
      return await backOfficeService.clearCompany(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  clearCompanyKeys: async ({}, payload) => {
    try {
      return await backOfficeService.clearCompanyKeys(payload);
    } catch ({ message }) {
      console.error(message);
    }
  },
  deleteCompany: async ({ commit }, payload) => {
    try {
      await backOfficeService.deleteCompany(payload);

      const companySettings = [
        {
          name: 'deleted',
          value: true,
        },
        {
          name: 'workflowsEnabled',
          value: false,
        },
      ];

      await Promise.all(
        companySettings.map(({ name, value }) =>
          backOfficeService.updateSettings({
            companyId: payload.companyId,
            name,
            value,
          }),
        ),
      );

      commit('SET_COMPANY_DELETED', true);
    } catch ({ message }) {
      console.error(message);
    }
  },
  toggleCompanyActiveState: async ({ commit, getters, rootGetters, dispatch }) => {
    try {
      const {
        companyDeactivated: isReactivatingCompany,
        companySettingsState,
        companyAdapters,
        companyUsers,
      } = getters;
      const workflowsEnabled = rootGetters['settings/workflowsEnabled'];
      const isDeactivatingCompany = !isReactivatingCompany;
      const promises = [];

      if (isDeactivatingCompany) {
        await dispatch('deleteCompany', {
          companyId: Vue.auth.user()?.selectedCompany?.id,
          deactivatedAt: Date.now(),
          byUserId: Vue.auth.user().id,
          reason: 'deactivate',
        });
        await dispatch(
          'settings/updateCompanySetting',
          {
            companyId: Vue.auth.user()?.selectedCompany?.id,
            name: 'activatedAt',
            value: null,
          },
          { root: true },
        );
      } else {
        // activate adapters
        for await (const adapter of companyAdapters) {
          const adapterClone = cloneDeep(adapter);
          adapterClone.active = !companySettingsState?.adapters[adapter.name]?.active;
          promises.push(dispatch('toggleCompanyAdapterType', adapterClone));
        }

        // activate users
        for await (const user of companyUsers.filter(
          (u) => !!u.activeInCompany || companySettingsState?.users[u.id]?.active,
        )) {
          promises.push(
            dispatch(
              'settings/updateCompanyUser',
              {
                userId: user.id,
                companyId: user.companyId,
                email: user.email,
                active: companySettingsState?.users[user.id]?.active,
              },
              { root: true },
            ),
          );
        }

        const currentUser = Vue.auth.user();
        const newWorkflowEnabledState = companySettingsState?.workflowEnabled;

        if (!isEqual(newWorkflowEnabledState, workflowsEnabled)) {
          promises.push(
            ...[
              dispatch(
                'settings/updateCompanySetting',
                {
                  companyId: currentUser?.selectedCompany?.id,
                  name: 'workflowsEnabled',
                  value: newWorkflowEnabledState,
                },
                { root: true },
              ),
              dispatch(
                'activityLog/addActivityLogNoteData',
                {
                  companyId: currentUser?.selectedCompany?.id,
                  workflowEnabled: newWorkflowEnabledState,
                  type: 'workflowEvent',
                  userId: currentUser.id,
                },
                { root: true },
              ),
            ],
          );
        }

        promises.push(
          dispatch(
            'settings/updateCompanySetting',
            {
              companyId: currentUser?.selectedCompany?.id,
              name: 'deactivatedAt',
              value: null,
            },
            { root: true },
          ),
          dispatch(
            'settings/updateCompanySetting',
            {
              companyId: currentUser?.selectedCompany?.id,
              name: 'activatedAt',
              value: Date.now(),
            },
            { root: true },
          ),
        );

        await Promise.all(promises);
      }

      commit('SET_COMPANY_DEACTIVATED', isDeactivatingCompany);
    } catch ({ message }) {
      console.error(message);
    }
  },
  createAdapterEmail: async ({ commit, dispatch }, { type, adapter, mode, allowNotExistsInvoices }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      const region = import.meta.env.VITE_APP_REGION;

      const payload = {
        companyId,
        _id: `${generateShortHash(16, true)}.${type}@${region === 'us' ? 'us.' : ''}datafeed.gaviti.com`,
        adapter,
      };

      if (isEqual(type, 'attachment')) {
        payload.isAttachmentAdapter = true;
        payload.mode = mode;
        payload.allowNotExistsInvoices = allowNotExistsInvoices;
      }

      await backOfficeService.createAdapterEmail(payload);

      await dispatch('fetchAdapterEmails', companyId);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  exportAdapterConfig: async (_, adapter) => {
    try {
      if (isEqual(adapter.code, 'qbwc2')) {
        const region = import.meta.env.VITE_APP_REGION;

        const xml = qbwcTemplate({
          region: region === 'us' ? 'us.' : '',
          name: adapter.name,
          token: adapter.token,
          ownerId: uuidv4(),
          fileId: uuidv4(),
        });

        const xmlContent = `data:text/xml;charset=utf-8,${xml}`;
        const url = encodeURI(xmlContent);
        const link = document.createElement('a');

        link.href = url;
        link.download = 'GavitiQBWCAdapter.qwc';
        link.click();
        link.remove();
      } else {
        throw new Error(
          i18n.t('settings.backOffice.tooltips.exportConfigFileNotSupported', {
            code: adapter.code,
          }),
        );
      }
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchAdapterEmails: async ({ commit }, companyId) => {
    try {
      const adapterEmails = await backOfficeService.fetchAdapterEmails({ companyId });

      commit('SET_ADAPTER_EMAILS', adapterEmails);
    } catch ({ message }) {
      console.error(message);
    }
  },
  fetchBillingIds: async ({ commit }, companyId) => {
    try {
      const billingIds = await backOfficeService.fetchBillingIds({ companyId });

      commit('SET_BILLING_IDS', billingIds);
    } catch ({ message }) {
      console.error(message);
    }
  },
  updateAdapterEmail: async ({ commit, dispatch }, { _id, adapter, mode, allowNotExistsInvoices }) => {
    try {
      commit('UPDATE_ADAPTER_EMAIL', { _id, adapter });

      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      await backOfficeService.updateAdapterEmail({
        companyId,
        _id,
        adapter,
        mode,
        allowNotExistsInvoices,
      });

      await dispatch('fetchAdapterEmails', companyId);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  setGoLiveTimestamp: ({ commit }, value) => {
    commit('SET_GO_LIVE_TIMESTAMP', value);
  },
  updateGoLiveTimestamp: async ({ commit, getters }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      const { goLiveTimestamp } = getters;
      const value = new Date(goLiveTimestamp).getTime();

      await backOfficeService.updateSettings({
        companyId,
        name: 'goLiveTimestamp',
        value,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  updateBusinessType: async ({ commit }, value) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      commit('SET_BUSINESS_TYPE', value);

      await backOfficeService.updateSettings({
        companyId,
        name: 'businessType',
        value,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  createAdapterInstance: async ({ dispatch, rootGetters }, { adapterConfig, editMode }) => {
    const {
      selectedCompany: { id: companyId },
    } = Vue.auth.user();

    const adapter = Object.assign(
      ...Object.entries(adapterConfig).map(([key]) => {
        const adapterConfigValue = adapterConfig[key].value;

        if (isEqual(key, 'jobSettings')) {
          const emails = isNil(adapterConfigValue) ? [] : adapterConfigValue.toString().split(/[ ,]+/);
          const filteredEmails = emails.filter((email) => !isEmpty(email));

          return {
            [key]: {
              emails: {
                error: filteredEmails,
              },
            },
          };
        }

        if (isEqual(key, 'customFieldsMapping')) {
          const customFieldMappings = {};

          for (const type in adapterConfigValue) {
            if (Object.hasOwnProperty.call(adapterConfigValue, type)) {
              const { mappings } = adapterConfigValue[type];

              if (!isEmpty(mappings)) {
                Object.values(mappings).map((mapping) => {
                  customFieldMappings[type] = {
                    ...customFieldMappings[type],
                    [mapping.key.value]: mapping.value.value,
                  };
                });
              }
            }
          }

          return {
            [key]: customFieldMappings,
          };
        }

        if (isEqual(key, 'objectFieldsMapping')) {
          const objectFieldsMapping = {};

          if (!isEmpty(adapterConfigValue)) {
            Object.values(adapterConfigValue).map((mapping) => {
              objectFieldsMapping[mapping.key.value] = mapping.value.value;
            });
          }

          return {
            [key]: objectFieldsMapping,
          };
        }

        if (isEqual(key, 'objectCalculatedFields')) {
          const objectCalculatedFields = {};

          if (!isEmpty(adapterConfigValue)) {
            Object.values(adapterConfigValue).map((mapping) => {
              objectCalculatedFields[mapping.key.value] = mapping.value.value;
            });
          }

          return {
            [key]: objectCalculatedFields,
          };
        }

        if (isEqual(key, 'transformations')) {
          const transformations = {};

          for (const type in adapterConfigValue) {
            if (Object.hasOwnProperty.call(adapterConfigValue, type)) {
              Object.values(adapterConfigValue[type]).map((value, index) => {
                const key = Object.keys(adapterConfigValue[type])[index];

                transformations[type] = {
                  ...transformations[type],
                  [key]: value,
                };
              });
            }
          }

          return {
            [key]: transformations,
          };
        }

        if (isEqual(key, 'filterRowRules')) {
          const filterRowRules = {};

          for (const type in adapterConfigValue) {
            if (Object.hasOwnProperty.call(adapterConfigValue, type)) {
              filterRowRules[type] = [];

              Object.values(adapterConfigValue[type]).map((value) => {
                if (
                  [IS_IN.value, IS_NOT_IN.value, STARTS_WITH_ONE_FROM_LIST.value].includes(value.type) &&
                  !isArray(value.value) &&
                  !isEmpty(value.value)
                ) {
                  value.value = value.value.split(/,+/);

                  if (isEqual(value.value.length, 1) && !isEqual(value.type, STARTS_WITH_ONE_FROM_LIST.value)) {
                    value.value = String(value.value[0]);
                  }

                  if (isArray(value.value) && !isEmpty(value.value)) {
                    value.value = value.value.map((v) => v.trim());
                  }
                }

                if ([IS_EMPTY.value, IS_NOT_EMPTY.value].includes(value.type)) {
                  delete value.value;
                }

                filterRowRules[type].push(value);
              });

              if (isEmpty(filterRowRules[type])) {
                delete filterRowRules[type];
              }
            }
          }

          return {
            [key]: filterRowRules,
          };
        }

        if (isEqual(key, 'calculatedFields')) {
          const calculatedFields = {};

          for (const type in adapterConfigValue) {
            if (Object.hasOwnProperty.call(adapterConfigValue, type)) {
              const { mappings } = adapterConfigValue[type];

              if (!isEmpty(mappings)) {
                Object.values(mappings).map((mapping) => {
                  calculatedFields[type] = {
                    ...calculatedFields[type],
                    [mapping.key.value]: mapping.value.value,
                  };
                });
              }
            }
          }

          return {
            [key]: calculatedFields,
          };
        }

        if (isEqual(key, 'skipFirstLines')) {
          const skipFirstLines = {};

          for (const type in adapterConfigValue) {
            if (Object.hasOwnProperty.call(adapterConfigValue, type)) {
              const value = adapterConfigValue[type];

              if (!isNil(value)) {
                skipFirstLines[type] = Number(value);
              }
            }
          }

          return {
            [key]: skipFirstLines,
          };
        }

        if (isEqual(key, 'updateInvoicesModeEnabled')) {
          return {
            [key]: adapterConfig[key],
          };
        }

        if (isEqual(key, 'uniqueIdentifierFieldNameMapping')) {
          return {
            [key]: adapterConfig[key],
          };
        }

        if (isEqual(key, 'supplementalData')) {
          return {
            [key]: adapterConfig[key],
          };
        }

        if (isEqual(key, 'companyFileLocation')) {
          const companyFileLocation = adapterConfigValue;

          if (!isNil(companyFileLocation) && isEmpty(companyFileLocation)) {
            return {
              [key]: null,
            };
          }
        }

        return {
          [key]: adapterConfigValue,
        };
      }),
    );

    if (isEqual(adapter.code, 'sftp') && isEmpty(adapter.password)) {
      delete adapter.password;
    }

    if (!editMode) {
      adapter.token = generateShortHash(40);

      if (isEqual(adapter.code, 'bluesnap') && isEqual(adapter.paymentType, 'CC')) {
        delete adapter.whiteListBlocks;
      }

      if (isEqual(adapter.paymentType, 'ACH')) {
        const paymentRestrictions = rootGetters['settings/paymentRestrictions'];
        const restrictions = isNil(paymentRestrictions) ? {} : cloneDeep(paymentRestrictions);

        if (isNil(restrictions.ACH)) {
          restrictions.ACH = [];
        }

        restrictions.ACH.push({
          name: adapter.name,
          currency: 'USD',
          min: 1,
          max: 10000000,
        });

        dispatch(
          'settings/updateCompanySetting',
          {
            companyId,
            name: 'paymentRestrictions',
            value: restrictions,
          },
          { root: true },
        );
      }

      if (isEqual(adapter.paymentType, 'EFT')) {
        const paymentRestrictions = rootGetters['settings/paymentRestrictions'];
        const restrictions = isNil(paymentRestrictions) ? {} : cloneDeep(paymentRestrictions);

        if (isNil(restrictions.EFT)) {
          restrictions.EFT = [];
        }

        restrictions.EFT.push({
          name: adapter.name,
          currency: 'CAD',
          min: 1,
          max: 10000000,
        });

        dispatch(
          'settings/updateCompanySetting',
          {
            companyId,
            name: 'paymentRestrictions',
            value: restrictions,
          },
          { root: true },
        );
      }

      if (isNil(adapter.fileNameNotPermanent) && !isEmpty(adapter.filePattern)) {
        delete adapter.filePattern;
      }

      if (!isNil(adapter.customFieldsMapping) && isEmpty(adapter.customFieldsMapping)) {
        delete adapter.customFieldsMapping;
      }

      if (!isNil(adapter.objectFieldsMapping) && isEmpty(adapter.objectFieldsMapping)) {
        delete adapter.objectFieldsMapping;
      }

      if (!isNil(adapter.objectCalculatedFields) && isEmpty(adapter.objectCalculatedFields)) {
        delete adapter.objectCalculatedFields;
      }

      if (!isNil(adapter.objectTransformations) && isEmpty(adapter.objectTransformations)) {
        delete adapter.objectTransformations;
      }

      if (!isNil(adapter.transformations) && isEmpty(adapter.transformations)) {
        delete adapter.transformations;
      }

      if (!isNil(adapter.filterRowRules) && isEmpty(adapter.filterRowRules)) {
        delete adapter.filterRowRules;
      }

      if (!isNil(adapter.calculatedFields) && isEmpty(adapter.calculatedFields)) {
        delete adapter.calculatedFields;
      }

      if (!isNil(adapter.skipFirstLines) && isEmpty(adapter.skipFirstLines)) {
        delete adapter.skipFirstLines;
      }

      if (isNil(adapter.companyFileLocation)) {
        delete adapter.companyFileLocation;
      }
    }

    return adapter;
  },
  triggerWorkflowCron: async () => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      await backOfficeService.triggerWorkflowCron({
        companyId,
      });
    } catch ({ message }) {
      console.error(message);
    }
  },
  deleteAdapter: async ({ commit, dispatch }, payload) => {
    try {
      commit('DELETE_COMPANY_ADAPTER', payload);

      await backOfficeService.deleteAdapter(payload);
      await dispatch('fetchCompanyAdaptersData', payload.companyId);
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  duplicateCompany: async ({ dispatch }, payload) => {
    try {
      await backOfficeService.duplicate(payload);

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
      Vue.prototype.$showToast('error', message);
    }
  },
  uploadAdapters: async ({ dispatch }, { companyId, formData }) => {
    try {
      await backOfficeService.uploadAdapters(formData);

      await dispatch('fetchCompanyAdaptersData', companyId);

      dispatch(
        'notifications/presentNotification',
        {
          type: 'success',
          visible: true,
          message: i18n.t('notifications.message'),
        },
        { root: true },
      );
    } catch ({ message }) {
      console.error(message);
      Vue.prototype.$showToast('error', message);
    }
  },
  toggleCompanyAPASettings: async ({ commit }, { name, value }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      commit('TOGGLE_COMPANY_APA_SETTINGS', { name, value });

      await backOfficeService.updateSettings({
        companyId,
        name,
        value,
      });
    } catch ({ message }) {
      console.error(message);
    } finally {
      commit('SET_RELOAD_PAGE', true);
    }
  },
  setCodatSettings: async (_, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      await backOfficeService.codatSettingsSet({
        companyId,
        ...payload,
      });
    } catch ({ message }) {
      console.error(message);

      Vue.prototype.$showToast('error', message);
    }
  },
  getCodatSettings: async (_, name) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      const res = await backOfficeService.codatSettingsGet({
        companyId,
        name,
      });

      if (res) return res;
    } catch ({ message }) {
      console.error(message);

      Vue.prototype.$showToast('error', message);
    }
  },
  getCodatPlatformKeys: async (_) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      const res = await backOfficeService.codatPlatformKeysGet({
        companyId,
      });

      if (res) return res;
    } catch ({ message }) {
      console.error(message);

      Vue.prototype.$showToast('error', message);
    }
  },
  setCodatSupplementalData: async (_, payload) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      await backOfficeService.codatSupplementalDataSet({
        companyId,
        ...payload,
      });
    } catch ({ message }) {
      console.error(message);

      Vue.prototype.$showToast('error', message);
    }
  },
  getCodatSupplementalData: async (_, name) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      const res = await backOfficeService.codatSupplementalDataGet({
        companyId,
        name,
      });

      if (res) return res;
    } catch ({ message }) {
      console.error(message);

      Vue.prototype.$showToast('error', message);
    }
  },
  fetchGavitiKeys: async ({ commit }) => {
    try {
      const {
        selectedCompany: { id: companyId },
      } = Vue.auth.user();

      const res = await backOfficeService.getGavitiKeys({
        companyId,
      });

      commit('SET_GAVITI_KEYS', res);
    } catch ({ message }) {
      console.error(message);

      Vue.prototype.$showToast('error', message);
    }
  },
};
