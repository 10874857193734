import { Email } from '@/services/shared-inbox/email.dto';

export default {
  SET_INBOX(state, { inbox, tab, isAppending }) {
    const emailMap = new Map();
    const existingEmails = isAppending ? state.inbox[tab] || [] : [];
    const newEmails = inbox[tab] || [];
    const allEmails = [...existingEmails, ...newEmails];

    for (const email of allEmails) {
      const emailObject = new Email(email);

      if (!emailMap.has(emailObject.id)) {
        emailMap.set(emailObject.id, emailObject);
      }
    }

    const emails = Array.from(emailMap.values());

    state.inbox = {
      ...state.inbox,
      [tab]: emails,
      stats: inbox.stats,
    };
  },
  SET_SELECTED_EMAIL(state, selectedEmail) {
    state.selectedEmail = selectedEmail;
  },
  SET_SELECTED_EMAIL_AS_READ(state, { id, tab }) {
    const emails = state.inbox[tab];

    for (let i = 0; i < emails.length; i += 1) {
      if (emails[i].id === id && emails[i].isNew) {
        emails[i].isNew = false;

        break;
      }
    }
  },
  UPDATE_ASSIGNED_CUSTOMER_ID(state, assignedCustomerId) {
    state.selectedEmail.assignedCustomerId = assignedCustomerId;
  },
  SET_NEW_MESSAGES_COUNT(state, newMessagesCount) {
    state.newMessagesCount = newMessagesCount;
  },
  UPDATE_NEW_MESSAGES_COUNT(state) {
    state.inbox.stats.new = Math.max(0, state.inbox.stats.new - 1);
  },
};
