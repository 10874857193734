import { Attachment } from './attachment.dto';

export class Email {
  constructor({
    id = null,
    from = null,
    to = [],
    cc = [],
    bcc = [],
    subject = null,
    body = null,
    bodyTeaser = null,
    attachments = [],
    assignedCustomer = null,
    assignedCustomerName = null,
    assignedCustomerId = null,
    readBy = [],
    createdAt = null,
    modifiedAt = null,
    note = null,
    isNew = false,
  }) {
    this.id = id;
    this.from = from;
    this.to = to;
    this.cc = cc;
    this.bcc = bcc;
    this.subject = subject;
    this.body = body;
    this.bodyTeaser = bodyTeaser;
    this.attachments = attachments.map((att) => new Attachment(att));
    this.assignedCustomer = assignedCustomer;
    this.assignedCustomerName = assignedCustomerName;
    this.assignedCustomerId = assignedCustomerId;
    this.readBy = readBy;
    this.createdAt = createdAt;
    this.modifiedAt = modifiedAt;
    this.note = note;
    this.isNew = isNew;
  }
}
