export default {
  inbox: {
    assigned: [],
    unassigned: [],
    all: [],
    stats: {
      total: 0,
      new: 0,
    },
  },
  selectedEmail: {},
  newMessagesCount: 0,
};
