export default {
  IS_CHART_LOADING(state, payload) {
    state.isChartLoading = payload;
  },
  SET_INVOICES_CHART(state, payload) {
    state.invoicesChart = payload;
  },
  SET_CUSTOMERS_CHART(state, payload) {
    state.customersChart = payload;
  },
  SET_BANK_CHART(state, payload) {
    state.bankChart = payload;
  },
  SET_BANK_TRANSACTIONS_BY_WEEK_DAYS_CHART(state, payload) {
    state.bankTransactionByWeekDaysChart = payload;
  },
  SET_BANK_TRANSACTIONS_BY_MONTH_WEEKS_CHART(state, payload) {
    state.bankTransactionByMonthWeeksChart = payload;
  },
  SET_PAYMENT_GATEWAY_CHART(state, payload) {
    state.paymentGatewayChart = payload;
  },
  SET_OVERDUE_INVOICES(state, payload) {
    state.overdueInvoices = payload;
  },
  SET_OUTSTANDING_INVOICES(state, payload) {
    state.outstandingInvoices = payload;
  },
  SET_UPCOMING_OVERDUE_AGING_INVOICES(state, payload) {
    state.upcomingOverdueAging = payload;
  },
  SET_CREDIT_LIMIT_USAGE_CHART(state, payload) {
    state.creditLimitUsage = payload;
  },
  SET_AGING_BUCKETS_CHART(state, payload) {
    state.agingBucketsChart = payload;
  },
  SET_TERMS_OF_PAYMENTS_CHART(state, payload) {
    state.termsOfPaymentsChart = payload;
  },
  SET_CUSTOMERS_RISK_CHART(state, payload) {
    state.customersRiskChart = payload;
  },
  SET_DSO_CHART(state, payload) {
    state.dsoChart = payload;
  },
  SET_PAST_PAYMENT(state, payload) {
    state.paymentsForecastChart.past = payload;
  },
  SET_FUTURE_PAYMENT(state, payload) {
    state.paymentsForecastChart.future = payload;
  },
  SET_FORECASTING_IS_CLICKABLE(state, payload) {
    state.paymentsForecastChart.isClickable = payload;
  },
  SET_TOTAL_AR_COMPARE_CHART(state, payload) {
    state.totalARCompareChart = payload;
  },
  SET_CUSTOMERS_OVERDUE_COMPARE_CHART(state, payload) {
    state.overdueCustomersCompareChart = payload;
  },
  SET_OUTSTANDING_INVOICES_COMPARE_CHART(state, payload) {
    state.outstandingInvoicesCompareChart = payload;
  },
  SET_AGING_BUCKETS_COMPARE_CHART(state, payload) {
    state.agingBucketsCompareChart = payload;
  },
  SET_PAST_COMPARE_PAYMENT(state, payload) {
    state.paymentsForecastCompareChart.past = payload;
  },
  SET_FUTURE_COMPARE_PAYMENT(state, payload) {
    state.paymentsForecastCompareChart.future = payload;
  },
  SET_COLLECTION_RATE_COMPARE_CHART(state, payload) {
    state.collectionRateCompareChart = payload;
  },
  SET_COMPARE_PAYMENT_TYPE(state, payload) {
    state.paymentsForecastCompareChart.type = payload;
  },
  SET_PAYMENTS_HISTORY_CHART: (state, payload) => {
    state.paymentsHistoryChart = payload;
  },
  SET_DISPUTES_CHART: (state, payload) => {
    state.disputesChart = payload;
  },
  SET_EMAIL_STATUS_METRICS_CHART: (state, payload) => {
    state.emailStatusMetricsChart = payload;
  },
  SET_DISPUTE_REASONS_CHART(state, payload) {
    state.disputeReasonsChart = payload;
  },
  SET_DISPUTE_AVG_DAYS_CHART(state, payload) {
    state.disputeAvgDaysChart = payload;
  },
};
