import { sharedInboxService } from '@/services';

export default {
  async fetchSharedInbox({ commit, dispatch }, { params, tab }) {
    try {
      const inbox = await sharedInboxService.list(params, tab);
      const isAppending = params.page > 1;

      commit('SET_INBOX', { inbox, tab, isAppending });

      await dispatch('fetchSharedInboxMessagesCount');
    } catch ({ message }) {
      console.error(message);
    }
  },
  async fetchSharedInboxMessagesCount({ commit }) {
    try {
      const { stats } = await sharedInboxService.list({
        page: 1,
        perPage: 1,
        fields: ['id'],
        search: {},
      });

      commit('SET_NEW_MESSAGES_COUNT', stats?.new || 0);
    } catch ({ message }) {
      console.error(message);
    }
  },
  async fetchSelectedEmail({ commit, dispatch }, id) {
    try {
      dispatch('unselectEmail');

      const selectedEmail = await sharedInboxService.findById(id);

      commit('SET_SELECTED_EMAIL', selectedEmail);
    } catch ({ message }) {
      console.error(message);
    }
  },
  async markSelectedEmailAsRead({ commit, dispatch }, { id, tab }) {
    try {
      await sharedInboxService.markAsRead({ id });

      commit('SET_SELECTED_EMAIL_AS_READ', { id, tab });
      commit('UPDATE_NEW_MESSAGES_COUNT');

      await dispatch('fetchSharedInboxMessagesCount');
    } catch ({ message }) {
      console.error(message);
    }
  },
  async assignCustomerToEmail(_, params) {
    try {
      await sharedInboxService.assignCustomer(params);
    } catch ({ message }) {
      console.error(message);
    }
  },
  async forwardEmail(_, params) {
    try {
      await sharedInboxService.forwardById(params);
    } catch ({ message }) {
      console.error(message);
    }
  },
  async replyEmail(_, params) {
    try {
      await sharedInboxService.replyById(params);
    } catch ({ message }) {
      console.error(message);
    }
  },
  setSelectedEmail({ commit }, email) {
    commit('UPDATE_INBOX_EMAIL', email);
  },
  unselectEmail({ commit }) {
    commit('SET_SELECTED_EMAIL', {});
  },
  updateAssignedCustomerId({ commit }, assignedCustomerId) {
    commit('UPDATE_ASSIGNED_CUSTOMER_ID', assignedCustomerId);
  },
};
