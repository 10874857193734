export class Inbox {
  constructor({
    assigned = [],
    unassigned = [],
    all = [],
    stats = {
      total: 0,
      new: 0,
    },
  }) {
    this.assigned = assigned;
    this.unassigned = unassigned;
    this.all = all;
    this.stats = stats;
  }
}
